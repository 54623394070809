<template>
	<div>
		<v-card color="primary" dark>
			<v-card-title class="py-0">
				<v-col class="px-0" cols="12" md="4">Simular salário para vendedor</v-col>
				<v-spacer />
				<v-col cols="12" lg="3" md="3" sm="6" class="px-0 pr-lg-3 pr-md-3 pr-sm-3">
					<v-autocomplete
						:items="filiaisFiltro"
						item-text="filial"
						item-value="idfilial"
						label="Filial"
						outlined
						dense
						:disabled="carregando"
						v-model="busca.idfilial"
						hide-details
						@change="listarVendedores(), limpa()"
					></v-autocomplete>
				</v-col>
				<v-col cols="12" lg="4" md="4" sm="6" class="px-0">
					<v-autocomplete
						label="Vendedor"
						v-model="busca.idvendedor"
						outlined
						dense
						:items="vendedores"
						item-text="vendedor"
						item-value="idvendedor"
						:disabled="carregando || busca.idfilial ==''"
						hide-details
						@change="get(), resetaOperacao()"
					></v-autocomplete>
				</v-col>
			</v-card-title>
		</v-card>
		<v-row class="mt-3">
			<v-col cols="12" md="6">
				<v-card>
					<v-card-title>Cenário Atual</v-card-title>
					<v-divider />
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-text class="py-2 font-weight-bold" style="font-size: 1.1em">
						<div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-sale</v-icon>
								<span>Valor de venda:</span>
								<span class="float-end">{{dados.totalvenda | formataDinheiro}}</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-bullseye</v-icon>
								<span>Meta do mês:</span>
								<span class="float-end">{{dados.metames | formataDinheiro}}</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-bullseye-arrow</v-icon>
								<span>Atingimento:</span>
								<span class="float-end" v-if="dados.atingimento">{{dados.atingimento.toFixed(2)}} %</span>
								<span class="float-end" v-else>0.00 %</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-minus</v-icon>
								<span>Dif. meta atingida:</span>
								<span class="float-end">{{dados.difmetaatingida | formataDinheiro}}</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-margin</v-icon>
								<span>Margem:</span>
								<span class="float-end" v-if="dados.margem">{{dados.margem.toFixed(2)}} %</span>
								<span class="float-end" v-else>0.00 %</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-sack-percent</v-icon>
								<span>Comissão:</span>
								<span class="float-end" v-if="dados.comissao">{{dados.comissao.toFixed(2)}} %</span>
								<span class="float-end" v-else>0.00 %</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-hand-coin</v-icon>
								<span>Salário atual:</span>
								<span class="float-end">{{dados.salario | formataDinheiro}}</span>
							</div>
						</div>
					</v-card-text>
					<v-divider />
					<v-card-title>Bonificação</v-card-title>
					<v-divider />
					<v-card-text class="py-2 font-weight-bold" style="font-size: 1.1em">
						<div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-sale</v-icon>
								<span>Valor de venda:</span>
								<span class="float-end">{{dados.vendabonificacao | formataDinheiro}}</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-hand-coin</v-icon>
								<span>Bonifição:</span>
								<span class="float-end">{{dados.bonificao | formataDinheiro}}</span>
							</div>
						</div>
					</v-card-text>
					<v-card-text
						class="py-3 px-4 font-weight-bold"
						style="background-color: #757575; color: #FFFFFF; font-size: 1.1em"
					>
						<span>Total salário:</span>
						<span
							class="float-end"
							v-if="busca.idvendedor && dados"
						>{{dados.bonificao + dados.salario | formataDinheiro}}</span>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6">
				<v-card>
					<v-card-title>
						Cenário de Simulação
						<v-spacer />
						<v-btn
							v-if="!showResultado"
							class="primary"
							width="103px"
							height="32px"
							@click="resetaOperacao()"
						>
							<v-icon class="pr-1" small>mdi-refresh</v-icon>Refazer
						</v-btn>
					</v-card-title>
					<v-divider />
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-text class="py-2 font-weight-bold" style="font-size: 1.1em" v-if="showResultado">
						<div class="py-2">
							<v-icon class="mr-2">mdi-sale</v-icon>
							<span>Valor de venda:</span>
							<v-text-field
								style="width: 35%"
								:disabled="!busca.idvendedor"
								dense
								hide-details
								class="float-end mt-n1"
								v-model="busca.totalvenda"
							></v-text-field>
						</div>
						<div class="py-2">
							<v-icon class="mr-2">mdi-margin</v-icon>
							<span>Margem:</span>
							<v-text-field
								style="width: 35%"
								:disabled="!busca.idvendedor"
								dense
								hide-details
								class="float-end mt-n1"
								v-model="busca.margem"
							></v-text-field>
						</div>
						<div class="py-2 pt-4">
							<v-btn
								width="100%"
								class="primary"
								@click="simularSalario()"
								:disabled="carregando || !busca.idvendedor"
							>Simular salário</v-btn>
						</div>
					</v-card-text>
					<div v-else>
						<v-card-text class="py-2 font-weight-bold" style="font-size: 1.1em">
							<div class="py-2">
								<v-icon class="mr-2">mdi-sale</v-icon>
								<span>Valor venda:</span>
								<span class="float-end">{{valorVendaSimulada(busca.totalvenda) | formataDinheiro}}</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-bullseye</v-icon>
								<span>Meta do mês:</span>
								<span class="float-end">{{dados.metames | formataDinheiro}}</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-bullseye-arrow</v-icon>
								<span>Atingimento:</span>
								<span
									class="float-end"
									v-if="dados.metames > 0"
								>{{(valorVendaSimulada(busca.totalvenda)/dados.metames*100).toFixed(2)}} %</span>
								<span class="float-end" v-else>0.00 %</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-minus</v-icon>
								<span>Dif. meta atingida:</span>
								<span
									class="float-end"
								>{{(dados.metames-valorVendaSimulada(busca.totalvenda)) | formataDinheiro}}</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-margin</v-icon>
								<span>Margem:</span>
								<span class="float-end" v-if="busca.margem">{{parseFloat(busca.margem).toFixed(2)}} %</span>
								<span class="float-end" v-else>0.00 %</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-sack-percent</v-icon>
								<span>Comissão:</span>
								<span
									class="float-end"
									v-if="campanhas.length > 0"
								>{{(campanhas.filter(v=> v.principal)[0].coeficiente * 100).toFixed(2)}} %</span>
								<span class="float-end" v-else>0.00 %</span>
							</div>
							<div class="py-2">
								<v-icon class="mr-2">mdi-hand-coin</v-icon>
								<span>Salário simulado:</span>
								<span
									class="float-end"
								>{{(campanhas.filter(v=> v.principal)[0].coeficiente) * valorVendaSimulada(busca.totalvenda) | formataDinheiro}}</span>
							</div>
						</v-card-text>
						<v-divider />
						<v-card-title>Bonificação</v-card-title>
						<v-divider />
						<v-card-text class="py-2 font-weight-bold" style="font-size: 1em">
							<v-row no-gutters class="py-2" v-for="(u,i) in campanhas.filter(v=> !v.principal)" :key="i">
								<v-col cols="7">
									<span class="text-truncate" style="font-size: 0.8em">{{u.campanha}}</span>
								</v-col>
								<v-col>
									<v-text-field
										suffix="R$"
										reverse
										type="number"
										dense
										hide-details
										class="mt-n1 mx-auto"
										v-model.number="calculos[i]"
									></v-text-field>
								</v-col>
								<v-col>
									<span
										class="float-end"
										v-if="calculos[i]"
									>{{calculos[i] * u.coeficiente | formataDinheiro}}</span>
									<span class="float-end" v-else>{{0 | formataDinheiro}}</span>
								</v-col>
							</v-row>
						</v-card-text>
						<v-card-text
							class="py-3 px-4 font-weight-bold"
							style="background-color: #757575; color: #FFFFFF; font-size: 1.1em"
						>
							<span>Total salário simulado:</span>
							<span class="float-end">{{valorSalarioSimulado() | formataDinheiro}}</span>
						</v-card-text>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import axios from "axios";

export default {
	name: "SimuladorSalario",
	mixins: [mixinFilial],
	data: () => ({
		carregando: false,
		snackbar: { status: false, timeout: 4000 },
		dados: {},
		campanhas: [],
		calculos: [],
		showResultado: true,
		cenarioAtual: {},
		teste: {},
		vendedores: [],
		busca: {
			flagtipovenda: false,
			idfilial: 0,
			idvendedor: 0,
			totalvenda: 0,
			coralegre: 0,
			margem: 0,
		},
		mascara: "",
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		get() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}comissao/simulacao/listar`, {
					idempresa: parseInt(this.busca.idfilial),
					idvendedor: parseInt(this.busca.idvendedor),
					flagtipovenda: true,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
					const date = new Date();
					const diaAtual = date.getDate();
					const date2 = new Date(
						date.getFullYear(),
						date.getMonth() + 1,
						0
					);
					const diaTotal = date2.getDate();
					const projecao =
						(this.dados.totalvenda * diaTotal) / diaAtual;
					if (projecao < this.dados.metames) {
						this.busca.totalvenda = parseFloat(
							this.dados.metames
						).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						});
					} else {
						this.busca.totalvenda = parseFloat(
							projecao
						).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						});
					}
					this.busca.margem = this.dados.margem.toFixed(2);
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		simularSalario() {
			this.carregando = true;
			this.campanhas = [];
			const venda = this.valorVendaSimulada(this.busca.totalvenda);
			return axios
				.post(`${this.backendUrl}comissao/simulacao/campanha/listar`, {
					margem: parseFloat(this.busca.margem / 100) || 0,
					meta: parseFloat(venda / this.dados.metames) || 0,
				})
				.then((res) => {
					if (res.data) {
						this.campanhas = res.data;
						this.carregando = false;
						this.showResultado = false;
					}
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		resetaOperacao() {
			this.showResultado = true;
			this.campanhas = [];
		},
		listarVendedores() {
			this.busca.idvendedor = 0;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}comissao/vendedor/listar`, {
					idempresa: this.busca.idfilial,
				})
				.then((res) => {
					this.vendedores = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		valorVendaSimulada(v) {
			const venda = parseFloat(
				v.replaceAll("R$", "").replaceAll(".", "").replaceAll(",", ".")
			);
			return venda;
		},
		valorSalarioSimulado() {
			let comissaoPadrao =
				this.campanhas.filter((v) => v.principal)[0].coeficiente *
				this.valorVendaSimulada(this.busca.totalvenda);
			let comissaoBonificacao = 0;
			this.campanhas
				.filter((v) => !v.principal)
				.map((v, i) => {
					if (this.calculos[i]) {
						comissaoBonificacao += this.calculos[i] * v.coeficiente;
					}
				});
			return comissaoPadrao + comissaoBonificacao;
		},
		limpa() {
			this.dados = {};
			this.campanhas = [];
			this.busca.margem = 0;
			this.busca.totalvenda = 0;
			this.busca.coralegre = 0;
			this.showResultado = true;
		},
	},
};
</script>
